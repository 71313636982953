<template>
  <v-menu
    ref="menu"
    v-model="dayChooser"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-x
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-select
        label="Chosen Days"
        v-model="selectItems"
        :items="selectItems"
        :error-messages="errorMessages"
        chips
        multiple
        deletable-chips
      >
        <template #append>
          <v-btn icon v-on="on">
            <v-icon>event</v-icon>
          </v-btn>
        </template>
        <template #no-data> </template>
      </v-select>
    </template>
    <v-date-picker v-model="pickedDates" @change="emitChange" no-title scrollable multiple>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="dayChooser = false"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "InputDateArray",
  components: {},
  mixins: [],
  props: {
    ordinal: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      required: false,
      default: null,
    },
    year: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: {
    parseDate(date) {
      const parsed = DateTime.fromISO(date);
      return this.ordinal ? parsed.ordinal : parsed;
    },
    ordinalToDate(item) {
      return DateTime.fromObject({
        year: this.year != null ? this.year : new Date().getFullYear(),
        ordinal: item,
        zone: "utc",
      });
    },
    emitChange(value = null) {
      const result = value || this.value;

      this.$emit("input", result);
      this.$emit("change");
    },
  },
  data() {
    return {
      dayChooser: null,
    };
  },
  computed: {
    pickedDates: {
      get() {
        const transformed = this.value.map(
          (item) => this.ordinalToDate(item).toISO().split("T")[0]
        );
        return transformed;
      },
      set(items) {
        this.emitChange(items.map(this.parseDate));
      },
    },
    selectItems: {
      get() {
        const items = this.value;
        // console.debug('InputDateArray')
        return items.map((item) => {
          const text = this.ordinal
            ? this.ordinalToDate(item)
            : DateTime.fromJSDate(new Date(item), { zone: "utc" });
          return {
            text: text.toLocaleString(DateTime.DATE_FULL),
            value: item,
          };
        });
      },
      set(items) {
        this.emitChange(items);
      },
    },
  },
  beforeCreate() {},
  created() {
    if (this.value == null || !Array.isArray(this.value)) {
      this.$emit("input", []);
    }
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
};
</script>

<style></style>
